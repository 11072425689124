.companyLogo {
  max-width: 140px;
  max-height: 90px;
}

.companyLogoBig {
  max-width: 300px;
  max-height: 170px;
}

.companyListItemLogo {
  max-width: 120px;
  max-height: 100px;
  margin: 1em;
}

.clientLogo {
  max-width: 200px;
  max-height: 200px;
}

.companyImageContainer {
  height: 110px;
  text-align: center;
  background-color: #fff !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.selectedCategory {
  border: '1px solid red';
}