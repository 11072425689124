/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

 .rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid var(--color-butelj);
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: var(--color-butelj);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}.rdt .form-control {
  background-color: white;
}

.opacity-50 {
  opacity: 0.5;
}

.recurring .form-group {
  margin-bottom: 1rem;
}

.recurring .col-sm-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.recurring .col-sm-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: auto;
  max-width: 16.666667%;
  display: inline-flex;
}

.recurring .col-sm-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: auto;
  max-width: 25%;
}

.recurring .col-sm-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.recurring .col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

.recurring .col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
}

.recurring col-3, .recurring col-9, .recurring .col-sm-1, .recurring .col-sm-2, .recurring .col-sm-3, .recurring .col-sm-6 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
}

.recurring .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 200;
  text-transform: uppercase;
  color: var(--color-butelj) !important;
}

.recurring .d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.recurring .align-items-sm-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.recurring .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.recurring .btn-group {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  vertical-align: middle;
  flex-flow: column;
}

.recurring .btn-group-toggle > .btn,
.recurring .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.recurring .btn-group-toggle > .btn input[type="radio"],
.recurring .btn-group-toggle > .btn input[type="checkbox"],
.recurring .btn-group-toggle > .btn-group > .btn input[type="radio"],
.recurring .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.recurring .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.recurring .btn:hover, .recurring .btn:focus {
  text-decoration: none;
}

.recurring .btn:focus, .recurring .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.recurring .btn.disabled, .recurring .btn:disabled {
  opacity: 0.65;
}

.recurring .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.recurring a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.recurring .btn-primary {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.recurring .btn-primary:hover {
  color: #fff;
  background-color: var(--color-butelj);
  border-color: var(--color-cactus);
}

.recurring .btn-primary:focus, .recurring .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.recurring .btn-primary.disabled, .recurring .btn-primary:disabled {
  color: #fff;
  background-color: var(--color-butelj);
  border-color: var(--color-cactus);
}

.recurring .btn-primary:not(:disabled):not(.disabled):active, .recurring .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--color-butelj);
  border-color: var(--color-cactus);
}

.recurring .btn-primary:not(:disabled):not(.disabled):active:focus, .recurring .btn-primary:not(:disabled):not(.disabled).active:focus,
.recurring .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.recurring .btn-group > .btn:first-child {
  margin-left: 0;
}

.recurring .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.recurring .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.recurring .btn-group > .btn:not(:first-child),
.recurring .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.recurring .pl-0,
.recurring .px-0 {
  padding-left: 0 !important;
}

.recurring .m-0 {
  margin: 0 !important;
}

.recurring .align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.recurring hr {
  display: none;
}

.recurring div.pt-3 {
  padding-top: 0rem !important;
}

@media (max-width: 768px) {
  .recurring .col-sm-1,
  .recurring .col-sm-2,
  .recurring .col-sm-3,
  .recurring .col-sm-6 {
    max-width: 100%;
  }

  .recurring .btn-group {
    display: flex;
  }
}

