.roundedCorners {
  border-radius: 15px !important;
}

.popoutShadow {
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.27) !important;
  -moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.27) !important;
  box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.27) !important;
}

.infoLabel {
  margin-top: 2px !important;
}

.padding {
  padding: 4px 0 4px;
}

.teal {
  color: #00b5ad
}