.google-maps {
  position: relative !important;;
  padding-bottom: 75% !important;;
  height: 0;
  overflow: hidden;
  margin: 15px;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}