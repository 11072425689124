.categoryImage {
  max-width: 200px;
}

.categoryImageMedium {
  max-width: 100px;
}

.categoryImageSmall {
  max-width: 32px;
}

.breadCrumb {
  font-size: 18px;
  line-height: 16px;
  color: #639780;
}

.ui.card,
.ui.cards>.card>.image {
  background-color: #ffffff;
  opacity: 1;
}

.pageHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.pageHeaderItem {
  padding: 0px 10px 0px;
}

.pageHeaderItem.headerLabel {
  margin-top: 4px;
}

#intro {
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.h3 {
  font-size: 18px;
  font-weight: 700;
}

.subheader {
  font-size: 12px;
  font-weight: 300;
  padding: 0;
  color: rgb(88, 89, 95);
}

.headerSpacing {
  height: 8px;
}

.spacing-m {
  height: 16px;
}
.spacing-lg {
  height: 24px;
}

.spacing-xs {
  height: 4px;
}

address {
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

th {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.orderModuleHeader {
  font-size: 14px;
  font-weight: 500;
}

.textBold {
  font-size: 14px;
  font-weight: 500;
  overflow-wrap: break-word;
}